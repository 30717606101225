import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitType);

window.gsap = gsap;

const applyGsapAnimations = () => {
  if (prefersReducedMotion() || isMobile()) {
    const body = document.querySelector("body");
    if (body) {
      body.style.setProperty("--parallaxDisabled", "true");
    }

    return;
  }

  const elements: HTMLElement[] = gsap.utils.toArray("*[data-gsap-animation]");

  elements.forEach((element) => {
    const animation = element.getAttribute("data-gsap-animation");
    if (!animation) return;

    const delay = parseFloat(element.getAttribute("data-gsap-delay") || "0");
    const offset = parseFloat(element.getAttribute("data-gsap-offset") || "20");
    const startTop = parseFloat(element.getAttribute("data-gsap-start-top") || "80");

    const props = { element, delay, offset, startTop };

    switch (animation) {
      case "fadeInUp":
        fadeInUp({ element, delay, offset, startTop, scrub: false });
        break;
      case "fadeInLeft":
        fadeInLeft(element, delay, offset);
        break;
      case "fillText":
        fillText(element, delay, offset);
        break;
      case "fadeInUpText":
        fadeInUpText(element, delay);
        break;
      case "listFadeInUp":
        listFadeInUp(props);
        break;
      case "listFadeInLeft":
        listFadeInLeft(element, delay);
        break;
      case "waveText":
        waveText(element);
        break;
      case "fadeInUpTextLines":
        fadeInUpTextLines(element, delay);
        break;
      case "photoCardsFadeInUp":
        photoCardsFadeInUp(element);
        break;
      case "fadeInUpDisabled":
        fadeInUpDisabled({ element, delay });
        break
      default:
        console.warn(`Unknown animation type: ${animation}`);
    }
  });
};

function prefersReducedMotion(): boolean {
  const parallaxDisabled = getComputedStyle(document.documentElement).getPropertyValue(
    "--parallax-disabled",
  );
  return parallaxDisabled === "true";
}

function isMobile(): boolean {
  return window.matchMedia("(max-width: 767px)").matches;
}

// TODO: Refactor all animations to use this interface
interface GsapAnimation {
  element: HTMLElement;
  delay?: number;
  offset?: number;
  scrub?: boolean;
  startTop?: number;
}

function fadeInUp(props: GsapAnimation) {
  const { element, delay, offset, scrub } = props;
  gsap.fromTo(
    element,
    { opacity: 0, y: offset },
    {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: element,
        start: `top ${props.startTop}%`,
        end: "top 50%",
        scrub,
      },
      delay,
    },
  );
}

function fadeInUpDisabled(props: GsapAnimation) {
  const { element, delay, offset } = props;
  gsap.fromTo(
    element,
    { opacity: 0, y: offset },
    {
      opacity: 1,
      y: 0,
      // scrollTrigger: {
      //   trigger: element,
      //   start: `top ${props.startTop}%`,
      //   end: "top 50%",
      //   scrub,
      // },
      delay,
    },
  );
}

function fadeInLeft(element: HTMLElement, delay: number, offset: number) {
  gsap.fromTo(
    element,
    { opacity: 0, x: offset },
    {
      opacity: 1,
      x: 0,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "top 50%",
        scrub: false,
      },
      delay,
    },
  );
}

function fillText(element: HTMLElement, delay: number, offset: number) {
  const split = new SplitType(element, { types: "words" });

  gsap.from(split.words, {
    opacity: 0.1,
    duration: 0.05,
    ease: "power1.out",
    stagger: 0.02,
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "top 50%",
      scrub: 0.8,
    },
  });

  gsap.fromTo(
    element,
    { opacity: 0, y: offset },
    {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "top 50%",
        scrub: 0.5,
      },
      delay,
    },
  );
}

function fadeInUpText(element: HTMLElement, delay: number) {
  // @ts-expect-error - SplitType types
  new SplitType(element, { types: "lines, words", lineClass: "word-line" });

  const words = element.querySelectorAll(".word-line .word");

  gsap.from(words, {
    y: "100%",
    skewX: "-10",
    opacity: 0,
    duration: 1.5,
    stagger: 0.05,
    ease: "expo.out",
    scrollTrigger: {
      trigger: element,
      start: "top 80%", // 65
      end: "top 40%",
      scrub: false,
    },
    delay,
  });

  gsap.fromTo(
    element,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      delay: delay,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "top 40%",
        scrub: false,
      },
    },
  );
}

function fadeInUpTextLines(element: HTMLElement, delay: number) {
  const text = new SplitType(element, { types: "lines" });
  const textLines = text.lines;

  gsap.fromTo(
    textLines,
    {
      y: 20,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      stagger: 0.25,
      duration: 0.5,
      ease: "power1.out",
      delay: delay,
    },
  );

  gsap.fromTo(
    element,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      delay: delay,
    },
  );
}

function listFadeInUp(props: GsapAnimation) {
  const { element, startTop } = props;
  const items = element.querySelectorAll("*[data-gsap-animation-item]");
  if (!items) return;

  gsap.fromTo(
    items,
    { opacity: 0, y: 40 },
    {
      opacity: 1,
      y: 0,
      duration: 0.5,
      stagger: {
        each: 0.15,
        from: "start",
      },
      scrollTrigger: {
        trigger: element,
        start: `top ${startTop}%`,
        end: "top 60%",
        scrub: false,
      },
      delay: 0.3,
    },
  );

  gsap.fromTo(
    element,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      scrollTrigger: {
        trigger: element,
        start: `top ${startTop}%`,
        end: "top 60%",
        scrub: false,
      },
    },
  );
}

function listFadeInLeft(element: HTMLElement, delay: number) {
  const items = element.querySelectorAll("*[data-gsap-animation-item]");
  if (!items) return;

  gsap.fromTo(
    items,
    { opacity: 0, x: 40 },
    {
      opacity: 1,
      x: 0,
      duration: 0.5,
      stagger: {
        each: 0.15,
        from: "start",
      },
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "top 60%",
        scrub: false,
      },
      // delay: 0.3,
    },
  );
}

function waveText(element: HTMLElement) {
  const text = new SplitType(element, { types: "chars" });
  const chars = text.chars;

  gsap.fromTo(
    chars,
    {
      y: 40,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      stagger: 0.02,
      duration: 0.4,
      ease: "power4.out",
    },
  );

  gsap.fromTo(
    element,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
    },
  );
}

function photoCardsFadeInUp(element: HTMLElement) {
  const items = element.querySelectorAll("*[data-gsap-animation-item]");
  if (!items) return;

  items.forEach((item) => {
    const translateX = parseFloat(item.getAttribute("data-gsap-translate-x") || "0");
    const translateY = parseFloat(item.getAttribute("data-gsap-translate-y") || "0");

    const rotate = parseFloat(item.getAttribute("data-gsap-rotate") || "0");

    const translateYFrom = parseFloat(item.getAttribute("data-gsap-translate-y-from") || "0");
    const translateXFrom = parseFloat(item.getAttribute("data-gsap-translate-x-from") || "0");

    gsap.fromTo(
      item,
      {
        opacity: 1,
        x: `${translateXFrom}rem`,
        y: `${translateYFrom}rem`,
        // rotate: 0,
      },
      {
        opacity: 1,
        x: `${translateX}rem`,
        y: `${translateY}rem`,

        // x: `${translateXFrom}rem`,
        // y: `${translateYFrom}rem`,

        rotate: rotate,
        duration: 1,
        stagger: {
          each: 0.15,
          from: "start",
        },
        scrollTrigger: {
          trigger: element,
          start: "top 70%",
          end: "top 10%",
          scrub: true,
        },
        delay: 0.3,
      },
    );

    gsap.fromTo(
      element,
      { opacity: 0, y: 0 },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 70%",
          end: "top 50%",
          scrub: false,
        },
      },
    );
  });
}

export { applyGsapAnimations };

// Run immediately after HTML will be processed
applyGsapAnimations();

document.addEventListener("astro:after-swap", () => {
  applyGsapAnimations();
});
